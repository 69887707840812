import PropTypes from 'prop-types';

const SiteLayout = ({ children }) => children;

SiteLayout.propTypes = {
  children: PropTypes.node,
};

SiteLayout.defaultProps = {
  children: null,
};

export default SiteLayout;
