import Store from 'conf-store';
import camelcase from 'camelcase-keys';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../tailwind.config';

const env = process.env.NODE_ENV || 'development';

const store = new Store({
  env,

  apollo: {
    http: {
      uri: 'https://api.roundhillmusic.com/graphql',
      credentials: 'same-origin',
    },
  },

  apiUrl: 'https://api.roundhillmusic.com',

  // Expose TailwindCSS config values
  tailwind: camelcase(resolveConfig(tailwindConfig).theme, { deep: true }),

  mediaUrls: {
    s3: 'https://s3-us-east-2.amazonaws.com/rhm-assets',
    imgix: 'https://rhm-assets.imgix.net',
  },

  favicon: 'https://rhm-assets.s3.us-east-2.amazonaws.com/u/2021/06/favicon.png',
  opengraph: {
    url: 'https://soundhill.com',
    image: 'https://rhm-assets.s3.us-east-2.amazonaws.com/u/2021/06/facebook.png',
    title: 'Sound Hill Music',
    description:
      'Sound Hill takes a hands-on approach to searching for and optimizing assets in the Neighboring Rights space by utilizing our worldwide network of relationships with international collection societies',
  },
});

const getConfig = (key, criteria = {}) => store.get(key, { env, ...criteria });
export default getConfig;
