import { ApolloProvider } from '@apollo/client';
import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
import Script from 'next/script';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { useApollo } from 'lib/apolloClient';
import SiteLayout from 'components/SiteLayout';

import 'styles/style.css';

// Inject the FontAwesome CSS globally to avoid icon flicker
faConfig.autoAddCss = false;

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);

  return (
    <ApolloProvider client={apolloClient}>
      <SiteLayout>
        <Component {...pageProps} />
        <Script
          src="https://umami.globobeet.org/script.js"
          data-website-id="5c24e0b8-8acb-4c50-a25f-7722083dc385"
        />
      </SiteLayout>
    </ApolloProvider>
  );
};

export default App;
