const colors = require('tailwindcss/colors');

module.exports = {
  theme: {
    colors: {
      transparent: 'transparent',
      black: colors.black,
      white: colors.white,
      gray: colors.gray,
      pink: colors.pink,
    },

    container: {
      center: true,
      padding: '8vw',
    },

    extend: {
      fontFamily: {
        sans: ['Montserrat', 'sans-serif'],
      },
    },
  },

  purge: [
    './src/components/**/*.js',
    './src/components/**/*.module.css',
    './src/pages/**/*.js',
    './src/pages/**/*.module.css',
    './src/styles/base.css',
  ],
};
